<template>
  <v-card class="load-fail d-inline-block mx-auto pa-5">
    <div class="load-logo mx-auto text-center pb-3">
      <img class="mx-auto" :width="themeJSON.loader.icon_size" :src="themeJSON.loader.svg_code" />
    </div>
    <div class="load-content">
      <h1 class="load-title">
        Onze excuses...
      </h1>
      <div class="load-message">
        Er is iets misgegaan. Probeer het later nog eens. Of neem anders contact op met {{ dealerConfigData.initial_page_title}}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'LoadFailure',
  computed: mapState({
    dealerConfigData: state => state.dealerConfigData, // get data from store
    themeJSON: state => state.themeJSON
  })
};
</script>

<style lang="scss">
// cookie popup
.load-fail {
  max-width: 90%;
  .load-logo {
    max-width: 120px;
    img {
      max-width: 100%;
      display: block;
    }
  }
}
</style>